import '@/scss/main.scss'
import 'tiny-slider/dist/tiny-slider.css'

import { tns } from 'tiny-slider/src/tiny-slider'
import Alpine from 'alpinejs'

window.Alpine = Alpine

document.addEventListener('DOMContentLoaded', () => {
  // init tns
  if (document.querySelector('.articles__slider-items')) {
    const slider = tns({
      container: '.articles__slider-items',
      items: 1,
      slideBy: 'page',
      autoplay: false,
      gutter: 16,
      nav: false,
      mouseDrag: true,
      loop: false,
      prevButton: '.articles__slider-prev',
      nextButton: '.articles__slider-next',
      autoHeight: true
    })

    const setNumber = (number) => {
      slider.getInfo().container.closest('.articles__slider-slider').setAttribute('data-current', number)
    }

    setNumber(1)
    slider.events.on('indexChanged', () => setNumber(slider.getInfo().index + 1))
  }
})

document.addEventListener('alpine:init', () => {
  Alpine.data('siteHeader', () => ({
    navOpened: false,
    searchOpened: false,
    toggleNav () {
      this.searchOpened = false
      this.navOpened = !this.navOpened

      if (this.navOpened) {
        window.scrollTo(0, 0)
        document.body.style.overflow = 'hidden'
        return
      }
      document.body.style.overflow = 'auto'
    },
    closeNav () {
      this.navOpened = false
      document.body.style.overflow = 'auto'
    },
    toggleSearch () {
      this.navOpened = false
      this.searchOpened = !this.searchOpened
    },
    closeSearch () {
      this.searchOpened = false
    },
    closeAll () {
      this.navOpened = false
      this.searchOpened = false
      document.body.style.overflow = 'auto'
    },
    toggleActive (event) {
      const clickedItem = event.target.closest('.nav__item')

      event.target.closest('.nav__items').querySelectorAll('.nav__item.active').forEach(item => {
        if (item === clickedItem) return
        item.classList.remove('active')
      })
      event.target.closest('.nav__item').classList.toggle('active')
    }
  }))

  Alpine.data('homePosts', () => ({
    fetchArticles () {
      const data = { action: 'filter_list_posts', postsPerPage: 3, limit: 3 }
      if (![null, 'null'].includes(this.$refs.categorySelect.value)) data.category = this.$refs.categorySelect.value
      if (![null, 'null'].includes(this.$refs.dateSelect.value)) data.date = this.$refs.dateSelect.value

      fetchFilteredPosts(data, this.$refs.posts)
    }
  }))

  Alpine.data('categoryPosts', () => ({
    fetchArticles () {
      const category = this.$refs.categorySelect.value
      const date = this.$refs.dateSelect.value

      // apply get params and refresh page
      const url = new URL(window.location.href)
      if (![null, 'null'].includes(category)) url.searchParams.set('category', category)
      if (![null, 'null'].includes(date)) url.searchParams.set('date', date)

      // remove get params if not specified
      if ([null, 'null'].includes(category)) url.searchParams.delete('category')
      if ([null, 'null'].includes(date)) url.searchParams.delete('date')

      window.history.replaceState({}, '', url)

      // refresh page to get filtered posts
      window.location.reload()
    }
  }))
})

Alpine.start()

function fetchFilteredPosts (data, $postsWrapper) {
  $postsWrapper.classList.add('loading')

  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }

  fetch(`${window.baseurl}/wp-admin/admin-ajax.php`, {
    method: 'POST',
    body: formData
  })
    .then(response => response.text())
    .then(res => {
      $postsWrapper.innerHTML = '<div class="loader"><div class="lds-facebook"><div></div><div></div><div></div></div></div>'
      $postsWrapper.innerHTML += res
    })
    .catch(error => {
      console.error('Error:', error)
    })
    .finally(() => {
      $postsWrapper.classList.remove('loading')
    })
}
